export type CallMeBy = 'name' | 'fullname' | 'nickname';

export type User = {
  id: string;
  name: string;
  nickname?: string;
  picture?: string;
  email: string;
  superuser: boolean;
  callMeBy: CallMeBy;
  favoriteColor: string;
};

export type UserAPI = {
  id: string;
  name: string;
  nickname?: string;
  picture?: string;
  email: string;
  is_superuser: boolean;
  call_me_by: 'name' | 'fullname' | 'nickname';
  favorite_color?: string;
};

export const userFromApi = (data: UserAPI): User => {
  const {
    is_superuser: superuser,
    call_me_by: callMeBy,
    favorite_color: favoriteColor,
    ...user
  } = data;

  return {
    ...user,
    superuser: superuser,
    callMeBy,
    favoriteColor: favoriteColor ?? 'default',
  };
};

export const userToApi = (
  user: Partial<User>,
): Partial<Omit<UserAPI, 'id'>> => {
  return {
    ...user,
    is_superuser: user.superuser,
    call_me_by: user.callMeBy,
    favorite_color: user.favoriteColor,
  };
};
