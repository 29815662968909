import { Injectable, inject, signal } from '@angular/core';
import { User, UserAPI, userFromApi, userToApi } from '../../entities/user';
import { HttpClient } from '@angular/common/http';
import { apiUrls } from '../../../../environments/environments';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  http = inject(HttpClient);
  baseUrl = apiUrls.auth;
  user = signal<User | undefined>(undefined);

  async getCurrentUser(): Promise<User> {
    const url = this.baseUrl + 'users/current';
    const observable = this.http.get<{ response: UserAPI }>(url);
    const response = await firstValueFrom(observable);
    return userFromApi(response.response);
  }

  async updateCurrentUser(user: Partial<Omit<User, 'id'>>): Promise<User> {
    const url = this.baseUrl + 'users/current';
    const body = userToApi(user);
    const observable = this.http.patch<{ user: UserAPI }>(url, body);
    const response = await firstValueFrom(observable);
    return userFromApi(response.user);
  }

  async updateCurrentUserPicture(data: string): Promise<string> {
    const url = this.baseUrl + 'users/current/picture';
    const formData = new FormData();
    const blob = await (await fetch(data)).blob();
    formData.set('file', blob);
    const observable = this.http.post<{ picture: string }>(url, formData);
    const response = await firstValueFrom(observable);
    return response.picture;
  }
}
